<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import PedidoService from "@/services/PedidoService";
const itemService = new PedidoService();
import HelperJS from "@/functions/general";
const helper = new HelperJS();
import InfoModel from "@/classes/pedido";
const infoModel = new InfoModel();
/**
 * Invoice Detail component
 */
const nameSeccion = 'Pedido';
const namePlural = nameSeccion+'s';
const routeParentName = 'pedidos';
export default {
  page: {
    title: "Información del "+ namePlural,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: namePlural,
      info_page:{
        routeParentName: null,
        routeBack: null,
        nameSeccion: null,
      },
      objectData:{},
      statusPayment: [
        {
          value: 0,
          text: "Pendiente",
          class: "bg-warning",
        },
        {
          value: 1,
          text: "Pagado",
          class: "bg-success",
        },
        {
          value: 2  ,
          text: "Cancelado",
          class: "bg-danger",
        }
      ],
      
      method_payments:[
        {
          text: 'Tarjeta de crédito / débito',
          value: 0,
          icon: 'uil uil-card-atm'
        },
        {
          text: 'OXXO PAY',
          value: 1,
          icon: 'uil uil-store-alt'
        },
        {
          text: 'PayPal',
          value: 2,
          icon: 'uil uil-paypal-alt'
        },
        {
          text: 'Transferencia bancaria',
          value: 3,
          icon: 'mdi mdi-bank-transfer'
        },
        {
          text: 'Pago en destino / Efectivo',
          value: 4,
          icon: 'mdi mdi-home-currency-usd'
        }
      ],
      statusShipping: [
        {
          value: 0,
          text: "Pendiente",
          class: "bg-soft-warning",
        },
        {
          value: 1,
          text: "En ruta",
          class: "bg-soft-info",
        },
        {
          value: 2,
          text: "Entregado",
          class: "bg-soft-success",
        },
        {
          value: 3,
          text: "Cancelado",
          class: "bg-soft-danger",
        }
      ],
      items: [
        {
          text: namePlural,
        },
        {
          text: "Información del "+ nameSeccion,
          active: true,
        },
      ],
    };
  },
  middleware: "authentication",
  created(){

    this.initPage();
  },
  methods: {
    async initPage(){
        this.info_page.routeParentName = routeParentName;
        this.info_page.nameSeccion = nameSeccion;
        this.info_page.routeBack = '/'+routeParentName;
        this.objectData = JSON.parse(JSON.stringify(infoModel.fillable));
         
        this.actionCreate = false;
         let  text_page = 'Información del';

         await  itemService.getById(this.$route.params.id).then(async response=>{  
            let data = response.data.object_data;
            let objectData =   JSON.parse(JSON.stringify(new InfoModel(data)));
            this.objectData = objectData;
          })
        
        this.items[0].text= nameSeccion        
        this.items[1].text= text_page+' '+nameSeccion;
      },
    formatDate(date, format = "short"){
      return helper.formatDate(date, format);
    },
    formatMoney(money){
      return helper.formatMoney(money);
    },
    
    formatAddress(direccion, zona){
      let returnText = '';
      returnText += '#'+direccion.number+' '+direccion.street+' '+direccion.colony+', '+zona+' CP.'+direccion.zipcode;
      return returnText ;
    },
    cancelForm(){
      this.$router.push({
        path:'/'+routeParentName,
      });
    },
    }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
    
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="invoice-title p-4 " :class="statusShipping[objectData.shipping_status].class">
           
            <div class="float-end">
             <span class="status-big fw-bold">{{statusShipping[objectData.shipping_status].text}}</span>
            </div>
           
            <div class="text-muted" v-if="objectData.shipping_status===2">
              <p class="mb-1 text-dark fw-bold" >Recibido por: {{objectData.received}}</p>

              <p class="mb-1">
                Entregado por: {{objectData.user.name}}
              </p>
              <p>Fecha y hora: {{formatDate(objectData.shipping_date, 'complete_hour')}}</p>
            </div>
          </div>

          <hr class="mt-2" />
          
          <div class="card-body">
            <div class="invoice-title" >
        <div class="row">
            <div class="col-md-4">
              <div class="text-muted">
                  <div>
                    <h5 class="font-size-16 mb-1">Pago ID:</h5>
                    <p>#{{objectData.payment_id}}</p>
                  </div>
                </div>
              </div>
            <div class="col-md-4">
              <div class="text-muted">
                <div>
                    <h5 class="font-size-16 mb-1">Forma de pago:</h5>
                    <p>{{method_payments[objectData.method_payment].text}}</p>
                  </div>
                </div>
              </div>
            <div class="col-md-4">
              <div class="text-muted">
                <div>
                    <h5 class="font-size-16 mb-1">Estado de pago:</h5>
                    <span class="badge font-size-12 ms-2" :class="statusPayment[objectData.payment_status].class">{{statusPayment[objectData.payment_status].text}}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-4" v-if="objectData.method_payment===0">
                <h5 class="font-size-16 mb-1">INE Frontal</h5>
                <img :src="objectData.cliente.ine_front" class="img-fluid" alt="INE Frontal">
              </div>
              <div class="col-md-4" v-if="objectData.method_payment===0">
                <h5 class="font-size-16 mb-1">INE Reverso</h5>
                <img :src="objectData.cliente.ine_back" class="img-fluid" alt="INE Frontal">
              </div>
            </div>
              </div>
            </div>

    <hr class="my-4" />
          <div class="card-body">
            <div class="invoice-title" >
            <!--  <h4 class="float-end font-size-16" >
                Pago ID #{{objectData.payment_id}}
                <span class="badge font-size-12 ms-2" :class="statusPayment[objectData.payment_status].class">{{statusPayment[objectData.payment_status].text}}</span>
              </h4>-->
             
              <div class="text-muted">
                <p class="mb-1 text-dark fw-bold" >{{objectData.cliente.full_name}}</p>
                <p class="mb-1">
                  <i class="uil uil-envelope-alt me-1"></i> {{objectData.cliente.email}}
                </p>
                <p><i class="uil uil-phone me-1"></i> {{objectData.cliente.phone}}</p>
              </div>
            </div>




            <hr class="my-4" />

            <div class="row">
              <div class="col-sm-6">
                <div class="text-muted">
                  <h5 class="font-size-16 mb-3">Enviar a:</h5>
                  <h5 class="font-size-15 mb-2">{{objectData.direccion.full_name}}</h5>
                  <p class="mb-1">{{formatAddress(objectData.direccion, objectData.zona.name)}}</p>
                  <p class="mb-1" v-if="objectData.direccion.email">{{objectData.direccion.email}}</p>
                  <p v-if="objectData.direccion.phone">{{objectData.direccion.phone}}</p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="text-muted text-sm-end">
                  <div>
                    <h5 class="font-size-16 mb-1">Fecha de compra:</h5>
                    <p>{{formatDate(objectData.created_at, 'complete')}}</p>
                  </div>
                  <div class="mt-4">
                    <h5 class="font-size-16 mb-1">Fecha de entrega:</h5>
                    <p>{{formatDate(objectData.date, 'complete')}}</p>
                  </div>
                  <div class="mt-4">
                    <h5 class="font-size-16 mb-1">Horario:</h5>
                    <p>{{objectData.horario.text}}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="py-2">
              <h5 class="font-size-15">Resumen del pedido</h5>

              <div class="table-responsive">
                <table class="table table-nowrap table-centered mb-0">
                  <thead>
                    <tr>
                      <th style="width: 70px">No.</th>
                      <th>Arreglo</th>
                      <th>Precio</th>
                      <th>Cantidad</th>
                      <th class="text-end" style="width: 120px">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(arreglo, idx) in objectData.arreglos" :key="idx">
                      <th scope="row">{{idx+1}}</th>
                      <td>
                        <h5 class="font-size-15 mb-1">
                          {{arreglo.arreglo.name}}
                        </h5>
                  
                      </td>
                      <td>{{ formatMoney(arreglo.price) }}</td>
                      <td>{{ arreglo.quantity }}</td>
                      <td class="text-end">{{formatMoney(arreglo.price*arreglo.quantity)}}</td>
                    </tr>

                   

                    <tr>
                      <th scope="row" colspan="4" class="text-end">
                        Subtotal
                      </th>
                      <td class="text-end">{{ formatMoney(objectData.subtotal) }}</td>
                    </tr>
                    
                    <tr>
                      <th scope="row" colspan="4" class="border-0 text-end">
                       Envío
                      </th>
                      <td class="border-0 text-end">{{formatMoney(objectData.shipping)}}</td>
                    </tr>
            
                    <tr>
                      <th scope="row" colspan="4" class="border-0 text-end">
                        Total
                      </th>
                      <td class="border-0 text-end">
                        <h4 class="m-0">{{ formatMoney(objectData.total) }}</h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="d-print-none mt-4">
                <div class="float-end">
                 
                  <a  @click.prevent="cancelForm()" class="btn btn-primary w-md ms-1">Regresar</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
