export default class {

    constructor(obj = {}) {
        this.init(obj);
    }

  /**
  
   *
   * @param obj
   */
  init(obj = {}) {

       

        this.fillable = {
          id:null,
          shipping: 0,
          total: 0,
          facture: 0,
          method_payment: 0,
          payment_status: 0,
          shipping_status: 0,
          zona_id: null,
          subtotal: 0,
          payment_id: null, 
          cliente_id: null, 
          user_id: null, 
          direccion_id: null, 
          channel: 2, 
          date: null, 
          shipping_date: null, 
          send_time: null,
          message: null,
          horario: {},
          direccion: {},
          zona: {},
          cliente: {},
          arreglos: [],
          created_at: null,
          user:{},
          received: null
        };

        if (obj) {
            Object.keys(this.fillable).forEach( key => {
                this[ key ] = obj[ key ] || this.fillable[ key ];
            });
        }

    }

    /*castUser(obj) {

      if (obj.hasOwnProperty('name') || obj.hasOwnProperty('slug')) {

        return {
          id:obj.id,
          name: obj.name,
          slug: obj.slug,
          images:obj.images,
          preview: obj.preview,
          description: obj.description,
          include: obj.include,
          seo_title: obj.seo_title,
          seo_description: obj.seo_description,
          price: obj.price,
          price_off: obj.price_off, 
          categories: obj.categories,
          types:obj.types,
          image_front: obj.images.lenght?obj.images[0].path:null,
          status: obj.status
        }

      }

      return obj;

    }*/

   
}
